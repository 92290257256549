/**
 * A collection of Array mappers.  These functions are intended to be passed
 * into Array.prototype.map.
 * @module array/mappers
 */

/**
 * Maps an object to a specified member of that object
 * @function
 * @name toKey
 * @param {string} key - The key of the object to which the object should be
 * matched.
 * @returns {function} - The actual mapper function that will map the object to
 * the specified key.
 */
export const toKey = (key: Readonly<string>) => (object: Readonly<Record<string, unknown>>): unknown => object[key];
