/**
 * A collection of functions intended to help with common String operations.
 * @module string
 */

/**
 * Converts a string to camel case.
 * E.g. "Gabe's Names & Things" -> "gabesNamesThings"
 * @function
 * @name camelCase
 * @param {string} str - The string to be converted
 * @returns {string} - The formatted string
 */
export const camelCase = (str = ''): string => (str || '')
	.toString()
	.replace(/[^0-9a-zA-Z\-_ ]/gu, '')
	.replace(/-|_/gu, ' ')
	.replace(/\s+/u, ' ')
	.split(' ')
	.map(word => `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`)
	.join('')
	.replace(/^./u, firstLetter => firstLetter.toLowerCase());

/**
 * Converts a string to dash case.
 * E.g. "Gabe's Names & Things" -> "gabes-names-things"
 * @function
 * @name dashCase
 * @param {string} str - The string to be converted
 * @returns {string} - The formatted string
 */
export const dashCase = (str = ''): string => (str || '')
	.toString()
	.replace(/[^a-zA-Z0-9 -]/gu, '')
	.replace(/\s+/gu, '-')
	.replace(/-+/gu, '-')
	.toLowerCase();

/**
 * Converts a filepath to camel case.
 * E.g. "src/string/index.js" -> "srcStringIndexJs"
 * @function
 * @name pathToCamel
 * @param {string} str - The filepath string
 * @returns {string} - The formatted string
 */
export const pathToCamel = (str = ''): string => camelCase((str || '')
	.toString()
	.replace(/\//gu, '-')
	.replace(/\./gu, ' '));

/**
 * Converts a non-space-delimited string to a space-delimited string
 * E.g. "my-name-is-earl" -> "my name is earl"
 * @function
 * @name spaceCase
 * @param {string} str - The string to reformat
 * @returns {string} - The formatted string
 */
export const spaceCase = (str = ''): string => (str || '')
	.toString()
	.replace(/\s{2,}|-|_|,/gu, ' ');

/**
 * Converts a table-formatted string to title case.
 * E.g. "ASSOCIATION_USER_REPORT" -> "Association User Report"
 * @function
 * @name tableToTitle
 * @param {string} str - The string to be converted
 * @returns {string} - The formatted string
 */
export const tableToTitle = (str = ''): string => (str || '')
	.toString()
	.split('_')
	.map(word => `${word.charAt(0)}${word.slice(1).toLowerCase()}`)
	.join(' ');

/**
 * Converts a string to title case.
 * E.g. "this is a title" -> "This Is A Title"
 * @function
 * @name titleCase
 * @param {string} str - The string to be converted
 * @returns {string} - The formatted string
 */
export const titleCase = (str = ''): string => (str || '')
	.toString()
	.replace(/_/gu, ' ')
	.replace(/\s+/gu, ' ')
	.split(' ')
	.map(word => `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`)
	.join(' ');

/**
 * Trims indentation from a multi-line string
 * @function
 * @name trimIndent
 * @param {string} str - The string to be trimmed
 * @returns {string} - The formatted string
 */
export const trimIndent = (str = ''): string => (str || '')
	.toString()
	.trim()
	.split('\n')
	.map(s => s.trim())
	.join('\n');

/**
 * Converts a string to url case.
 * E.g. "Gabe's Names & Things" -> "gabes-names-and-things"
 * @function
 * @name urlCase
 * @param {string} str - The string to be converted
 * @returns {string} - The formatted string
 */
export const urlCase = (str = ''): string => (str || '')
	.toString()
	.toLowerCase()
	.replace(/&/gu, 'and')
	.replace(/\s/gu, '-')
	.replace(/[^a-z0-9-]/gu, '')
	.replace(/-+/gu, '-');
